.Inicio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 200vh;
  }
  
  .Inicio iframe {
    width: 100%;
    height: 80vh;
  }
  
  @media (min-width: 768px) {
    .Inicio iframe {
      height: 90vh;
      width: 90%;
    }
  }
  
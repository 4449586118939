.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    font-family: 'Helvetica', sans-serif;
  }
  
  .about-container p {
    margin-bottom: 20px;
  }
  
  .about-container h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .about-container ul {
    list-style: none;
    padding: 0;
  }
  
  .about-container ul li {
    margin-bottom: 10px;
  }
  
  .about-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .about-container a:hover {
    text-decoration: underline;
  }
  
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.navbar {
  background-image: url('/Users/fcojavier/Desktop/AppMuestra/formproveedor/public/fondo.webp');
  color: #2A2924;
  padding: 10px;
  width: 100%;
}

.nav-menu {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

body {
  background-image: url('/Users/fcojavier/Desktop/AppMuestra/formproveedor/public/claire-house-127234.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-item {
  margin-right: 10px;
}

.nav-item a {
  color: #2A2924;
  text-decoration: none;
}

.nav-item a:hover {
  text-decoration: underline;
}

/* Escala de grises para imágenes */
img {
  filter: grayscale(100%);
}

@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");

/* Estilos personalizados */
.form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color:transparent;
}

.form h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form label {
  font-weight: bold;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.form button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
}

.form button:hover {
  background-color: #0056b3;
}

.form label input[type="checkbox"] {
  margin-top: 10px;
  margin-bottom: 20px;
}
